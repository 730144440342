import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import brush from "../../../assets/images/icons/digiArt.svg";
import camera from "../../../assets/images/icons/camera.svg";
import picture from "../../../assets/images/icons/iconImage.svg";


import like2 from "../../../assets/images/icons/like2.svg";
import eye from "../../../assets/images/icons/eye.svg";
import share from "../../../assets/images/icons/share.svg";

import creations from "../../../assets/images/icons/creations.svg";
import artInsta from "../../../assets/images/icons/art-insta.png";
import artX from "../../../assets/images/icons/art-X.png";
import artYouTube from "../../../assets/images/icons/art-youTube.png";
import artTumbir from "../../../assets/images/icons/art-tumbir.png";




import artist from "../../../assets/images/artist1.png";

import art1 from "../../../assets/images/artItems/art1.png";
import art2 from "../../../assets/images/artItems/art2.png";
import art3 from "../../../assets/images/artItems/art3.png";
import art4 from "../../../assets/images/artItems/art4.png";
import art5 from "../../../assets/images/artItems/art5.png";
import art6 from "../../../assets/images/artItems/art6.png";



import "./ArtistDetails.css"

const ChipButton = [
  {
    label: 'Darkness outlet',
  },
  {
    label: 'Possibilities',
  },
  {
    label: 'Psychedelic',
  },

]
const settingsTagSlider = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 1500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 2,
  autoplay: false,
  autoplaySpeed: 0,
  zoom: true,
  pauseOnHover: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows: false,
        slidesToShow: 1,
        variableWidth: false,
      }
    }
  ]
};

const settingsCategory = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 3500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 2,
  autoplay: false,
  autoplaySpeed: 0,
  zoom: true,
  pauseOnHover: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: false,
      }
    }
  ]
};

const CategoryButton = [
  {
    icon: brush,
    label: 'Pure static digital art',
  },
  {
    icon: camera,
    label: 'Photography',
  },
  {
    icon: picture,
    label: 'Digitalized Physical Art',
  },

]





const ArtistDetails = () => {

  return (
    <div className="page-main-wrapper artist-view-wrapper f-wrp">
      <div className='container'>
        <div className='art-main-wrapper f-wrp'>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className='left-side-wrp f-wrp'>

                <div className='artist-block f-wrp'>
                  <span className='artist-img'><img src={artist} alt='like' /></span>
                  <div className='btn-wrap f-wrp'>
                    <ul>
                      <li><span className="block-btn initial-btn">View gallery</span></li>
                    </ul>
                  </div>
                </div>

                <div className='art-gallery-wrp f-wrp'>
                  <h5>Featured</h5>
                  <ul>
                    <li><span><img src={art1} alt='gallery' /></span></li>
                    <li><span><img src={art2} alt='gallery' /></span></li>
                    <li><span><img src={art3} alt='gallery' /></span></li>
                    <li><span><img src={art4} alt='gallery' /></span></li>
                    <li><span><img src={art5} alt='gallery' /></span></li>
                    <li><span><img src={art6} alt='gallery' /></span></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className='right-content-sec f-wrp'>
                <div className='profile-card-wrp f-wrp'>
                  <div className='profile-card-header f-wrp'>
                    <h2><p>Artist name <Button className='icon'><img src={share} alt='share' /></Button></p>
                      <div className='btn-wrap'>
                        <ul>
                          <li><span className="block-btn initial-btn">Work with artist</span></li>
                        </ul>
                      </div>
                    </h2>
                    <div className='art-details f-wrp'>
                      <p><img src={creations} alt='like' /> 8 Creations  &nbsp;&nbsp;<img src={like2} alt='like' /> 368 Likes  &nbsp;&nbsp; <img src={eye} alt='like' /> 4.3k Views</p>
                      <p>Joined 1 year ago &nbsp;&nbsp; Location</p>
                    </div>
                    <div className='social-block f-wrp'>
                      <div className='social-head'>
                        <p><b>Follow me</b></p>
                        <ul className='social-list'>
                          <li><Link to={'#'} className='social-icon'><img src={artInsta} alt='share' /></Link></li>
                          <li><Link to={'#'} className='social-icon'><img src={artX} alt='share' /></Link></li>
                          <li><Link to={'#'} className='social-icon'><img src={artYouTube} alt='share' /></Link></li>
                          <li><Link to={'#'} className='social-icon'><img src={artTumbir} alt='share' /></Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='profile-card-con f-wrp'>
                    <h4>About me</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure

                    </p>
                    <br />
                    <h4>Artistic inspiration</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam minima laboriosam quo, eius quisquam? Facilis tempora eveniet reiciendis. Nesciunt iure
                    </p>
                    <br />
                    <h4>Tools</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam </p>
                    <br />
                    <h4>More</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque nulla earum dicta nesciunt, cum eaque a alias qui ipsa sit laborum, nihil in optio nam </p>
                    
                    <div className='category-wrapper f-wrp'>
                      <h4>Art</h4>
                      <div className='category-slider f-wrp'>
                        <Slider {...settingsCategory}>
                          {CategoryButton.map((element, key) => (
                            <div className="each-categry-btn f-wrp" key={key}>
                              <span className="chip-btn"><img src={element.icon} alt="icon" /> {element.label}</span>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>

                    <br />
                    <div className='tag-wrapper f-wrp'>
                      <h4>Tags</h4>
                      <div className='art-tag-btn f-wrp'>
                        <Slider {...settingsTagSlider}>
                          {ChipButton.map((element, key) => (
                            <div key={key}>
                              <span className="chip-btn">{element.label}</span>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistDetails;
