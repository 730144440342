import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import bannerRyt from "../../../../assets/images/tandas/bannerRyt.png";
import aboutDevice from "../../../../assets/images/tandas/aboutDevice.png";
import challenge from "../../../../assets/images/tandas/challenge.png";
import solutionImg from "../../../../assets/images/tandas/solutionImg.png";
import mapPin from "../../../../assets/images/icons/MapPin.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/images/icons/ArrowRight.svg";
import "../projects.css";
import "./tandas.css";
import { ScrollToTopOnMount } from "../../../../components/scrollToTop/scrollToTop";

const Tandas = () => {
  ScrollToTopOnMount();
  return (
    <div className="page-main-wrapper f-wrp">
      <div className="tadas-main-wrp f-wrp">
        <div className="banner-main-wrapper f-wrp">
          <div className="container">
            <div className="banner-con-box f-wrp">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="banner__title">
                    <div className="chips-container">
                      <div className="chips">Mobile App</div>
                      <div className="chips">Web App</div>
                    </div>
                    <p className="banner-text">
                      Tandas App
                    </p>
                    <p>
                      <img className="icon" src={mapPin} alt="Map pin" /> Californa, US
                    </p>
                    
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="baner-image f-wrp">
                    <span><img src={bannerRyt} alt="tandas" /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="project-switch-wrp f-wrp" style={{ backgroundColor: '#101010' }}>
          <div className="container">
            <ul>
              <li>
                <Link to={"/projects/educations"}>
                  <label>Previous</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/projects/teslabots"}>
                  <label>Next project</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div> */}

        <div className="abt-main-wrapper f-wrp" style={{ backgroundColor: '#101010' }}>
          <div className="container">
            <div className="abt-con-wrp f-wrp">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="abt-img-box f-wrp">
                    <span><img src={aboutDevice} alt="tandas" /></span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="abt-content-box f-wrp">
                    <h1>About the app</h1>
                    <p>Introducing an innovative app, revolutionizing traditional tandas: Seamlessly organize and participate in rotating savings and credit associations online, empowering communities to efficiently pool resources and achieve financial goals together.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="challenge-main-wrapper f-wrp" style={{ backgroundColor: '#fff' }}>
          <div className="container">
            <div className="challenge-con-wrp f-wrp">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="challenge-content-box f-wrp">
                    <h1>The challenge</h1>
                    <p>Coordinating meetings, managing contributions, and ensuring fairness among participants pose significant challenges. Moreover, for those living far from their communities or without access to in-person networks, participating in tandas becomes even more difficult. The challenge lies in modernizing this tradition to make it accessible, convenient, and secure for everyone. </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="challenge-img-box f-wrp">
                    <span><img src={challenge} alt="tandas" /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="solution-main-wrapper f-wrp" style={{ backgroundColor: '#5B2689' }}>
          <div className="container">
            <div className="solution-con-wrp f-wrp">
              <h1>The Solution</h1>
              <p>By bringing the tanda experience online, we eliminate geographical barriers and simplify the process for participants. Through our user-friendly platform, users can easily create, join, and manage tandas from the comfort of their homes or on the go. With features such as automated contribution tracking, secure transactions, and built-in communication tools, our app ensures transparency, fairness, and efficiency in every tanda. </p>
            </div>
          </div>
          <div className="solution-img-box f-wrp">
            <span><img src={solutionImg} alt="tandas" /></span>
          </div>
        </div>


        <div className="project-switch-wrp f-wrp" style={{ backgroundColor: '#5B2689' }}>
          <div className="container">
            <ul>
              <li>
                <Link to={"/projects/educations"}>
                  <label>Previous</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/projects/education-platform"}>
                  <label>Next project</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-content-wrp f-wrp">
          <div className="foot-con-sec f-wrp blk-txt-wrp">
            <div className="container">
              <h1 className="main-title">Let’s do this!</h1>
              <Link to={"/start-my-project"} className="sqr-btn">
                Start my project
              </Link>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Tandas;
