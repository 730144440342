import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import mainBanner1 from "../../../../assets/images/carousel/xmedpro/banner/banner1.png";
import mainBanner2 from "../../../../assets/images/carousel/xmedpro/banner/banner2.png";
import mainBanner3 from "../../../../assets/images/carousel/xmedpro/banner/banner3.png";
import mainBanner4 from "../../../../assets/images/carousel/xmedpro/banner/banner4.png";
import mainBanner5 from "../../../../assets/images/carousel/xmedpro/banner/banner5.png";
import mainBanner6 from "../../../../assets/images/carousel/xmedpro/banner/banner6.png";
import mainBanner7 from "../../../../assets/images/carousel/xmedpro/banner/banner7.png";

import aboutSlider1 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider1.png";
import aboutSlider2 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider2.png";
import aboutSlider3 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider3.png";
import aboutSlider4 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider4.png";
import aboutSlider5 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider5.png";
import aboutSlider6 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider6.png";
import aboutSlider7 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider7.png";
import aboutSlider8 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider8.png";
import aboutSlider9 from "../../../../assets/images/carousel/xmedpro/aboutSlider/slider9.png";

import xmedLogo from "../../../../assets/images/xmedpro/xmedLogo.png";
import testOverview from "../../../../assets/images/xmedpro/testOverview.png";
import takeExam from "../../../../assets/images/xmedpro/takeExam.png";
import createATest from "../../../../assets/images/xmedpro/createATest.png";
import xmedMob from "../../../../assets/images/xmedpro/xmedMob.png";

import "../projects.css";
import "./xmed.css";
import { ScrollToTopOnMount } from "../../../../components/scrollToTop/scrollToTop";


const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  zoom: true,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 468,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};
const mainSliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  centerMode: true,
  speed: 1000,
  slidesToShow: 1.001,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  zoom: true,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        centerMode: false,
      }
    }

  ]
}

const Xmed = () => {
  ScrollToTopOnMount();
  return (
    <div className="page-main-wrapper xmedpro-project-wrapper f-wrp">
      <div className="project-wrapper f-wrp">
        <div className="project-banner-sec f-wrp">
          <div className="container">
            <div className="xmedpro-banner-con project-sub-banner f-wrp">
              <div className="banner-lft-con banner__title">
                <h1 className="banner-text">XMEDPRO</h1>
              </div>
              <div className="banner-ryt-con">
                <p>Immersive medical education experience</p>
                <div className="chips-container">
                  <div class="chips">Blockchain</div>
                  <div class="chips">Web app</div>
                </div>
              </div>
            </div>

            <div className="xmedpro-main-slider f-wrp">
              <Slider {...mainSliderSettings}>
                <div className="xmedpro-slider">
                  <img src={mainBanner1} alt="xmedpro" />
                </div>
                <div className="xmedpro-slider">
                  <img src={mainBanner2} alt="xmedpro" />
                </div>
                <div className="xmedpro-slider">
                  <img src={mainBanner3} alt="xmedpro" />
                </div>
                <div className="xmedpro-slider">
                  <img src={mainBanner4} alt="xmedpro" />
                </div>
                <div className="xmedpro-slider">
                  <img src={mainBanner5} alt="xmedpro" />
                </div>
                <div className="xmedpro-slider">
                  <img src={mainBanner6} alt="xmedpro" />
                </div>
                <div className="xmedpro-slider">
                  <img src={mainBanner7} alt="xmedpro" />
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="xmed-abt-wrapper f-wrp" id="about">
          <div className="container">
            <div className="abt-header-wrp f-wrp">
              <h1>About the app</h1>
              <p>An educational platform that offers high quality interactive resources initially designed for the medical community, and later extended to every educational discipline. </p>
            </div>

            <div className="xmed-abt-slider f-wrp">
              <div className="phone-wrp f-wrp">
                <div className="phone-sec-wrp f-wrp">
                  <div className="soc-slider-wrp f-wrp">
                    <Slider {...settings}>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider1} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider2} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider3} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider4} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider5} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider6} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider7} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider8} alt="xmedpro" />
                      </div>
                      <div className="each-item-sec f-wrp">
                        <img src={aboutSlider9} alt="xmedpro" />
                      </div>

                    </Slider>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="xmed-abt-sec-wrp f-wrp">
          <div className="container">
            <div className="xmed-abt-con f-wrp">
              <div className="row">
                <div className="col-lg-9 col-md-6 col-sm-6 col-xs-12">
                  <div className="xmed-abt-con-head f-wrp">
                    <h1>XMedPro: Elevating medical learning</h1>
                    <p>XMed Pro enhances the educational experience of Medical graduates with digital interactive tools and curated content. </p>
                  </div>
                  <div className="each-abt-wrp f-wrp">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="each-abt-box f-wrp mar-btm30">
                          <span><img src={xmedLogo} alt="xmedpro" /></span>
                        </div>
                        <div className="each-abt-box f-wrp">
                          <span><img src={testOverview} alt="xmedpro" /></span>
                          <div className="abt-con">
                            <p>TEST OVERVIEW</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div className="abt-main-box f-wrp">
                          <span><img src={xmedMob} alt="xmedpro" /></span>
                          {/* <span><img src={xmedMobBG} alt="xmedpro" /></span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="each-abt-box mar-btm15 f-wrp">
                    <span><img src={createATest} alt="xmedpro" /></span>
                    <div className="abt-con">
                      <p>CREATE  A TEST</p>
                    </div>
                  </div>
                  <div className="each-abt-box mar-btm30 f-wrp">
                    <span><img src={takeExam} alt="xmedpro" /></span>
                    <div className="abt-con">
                      <p>Take Exams</p>
                    </div>
                  </div>
                  {/* <div className="each-abt-box f-wrp">
                    <span><img src={createQN} alt="xmedpro" /></span>
                  </div> */}
                </div>

              </div>
            </div>

            <div className="project-switch-wrp f-wrp">
              <ul>
                <li>
                  <Link to={"/projects/real-estate"}>
                    <label>Previous</label>
                    <span className="longArrow">
                      <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to={"/projects/financial-services"}>
                    <label>Next project</label>
                    <span className="longArrow">
                      <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>

          </div>
          <div className="abt-pad-foot f-wrp" style={{ paddingBottom: '150px' }} />
        </div>

      </div>
      <div className="footer-content-wrp f-wrp">
        <div className="foot-con-sec f-wrp blk-txt-wrp">
          <div className="container">
            <h1 className="main-title">Let’s do this!</h1>
            <Link to={"/start-my-project"} className="sqr-btn">
              Start my project
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Xmed;
