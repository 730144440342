import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import pro0 from "../../../assets/images/carousel/project/pro0.png";
import pro1 from "../../../assets/images/projects/shades.png";
import pro2 from "../../../assets/images/projects/homeRemodeling.png";
import pro3 from "../../../assets/images/projects/childcareBanner.png";
import pro4 from "../../../assets/images/carousel/Grace/grace.png";
import pro5 from "../../../assets/images/carousel/Chatbot/pro1.png";
import pro6 from "../../../assets/images/carousel/Solar/solar.png";
import pro7 from "../../../assets/images/projects/LuxuryRealEstate.png";
import pro8 from "../../../assets/images/projects/education.png";
import pro9 from "../../../assets/images/projects/tandas.png";
import pro10 from "../../../assets/images/projects/PPBG.png";

import { Link } from "react-router-dom";
import { ReactComponent as ChevDown } from "../../../assets/images/icons/ChevDown.svg";
import "./projects.css";
import { ScrollToTopOnMount } from "../../../components/scrollToTop/scrollToTop";


const prodcutDetails = [
  {
    icon: pro0,
    productName: 'Blockchain & NFTs',
    description: "The most awaited NFT collection of digital art, a concept of Cool Teslabots under a series of different attires and looks. Smart contracts have designed to optimize gas and for commercial success. You can buy this Digital Art cool teslabots.io or in OpenSea.",
    link: '/projects/teslabots'
  },
  {
    icon: pro1,
    productName: 'Beauty & wellness',
    description: "Luxury beauty services app in Dubai. The app brings celebrity makeup artists, top hair stylists, nail technicians and massage therapists with VIP clients throughout Dubai, UAE.",
    link: '/projects/shades'
  },
  {
    icon: pro2,
    productName: 'Home remodeling',
    description: "First app delivering a precise real time quote for home remodeling projects. Also, allowing contractors to solve all of their back office and management challenges. A client can book and schedule a home remodeling project in less than 2 minutes.",
    link: '/projects/anyhnb'
  },
  {
    icon: pro3,
    productName: 'Education & Children',
    description: "This platform simplifies the life of busy parents and child care providers by providing a space to find each other based on preferences, price, scheduling and custom needs, guarantees the safety of both parties and facilitates all the admin processes.",
    link: '/projects/education'
  },
  {
    icon: pro4,
    productName: 'Luxury cars',
    description: "Grace is a luxury car inspired in in the French riviera and Montecarlo surroundings, combining design from 1920's with technology from 2020's. Grace is not only re-designing the future with its artistic looks, but it will be the first car to be sold on the blockchain as an NFT.",
    link: '/projects/grace'
  },
  {
    icon: pro5,
    productName: 'Mental Health',
    description: "Emotional support with Artificial Intelligence chatbot that helps people in El Salvador to deal with their daily challenges, provides advice, support and matches them with a mental health professional.",
    link: '/projects/health'
  },
  {
    icon: pro6,
    productName: 'Renewables',
    // description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    link: '/projects/renewables'
  },
  {
    icon: pro7,
    productName: 'Real Estate',
    description: "Discover a seamless blend of luxury property curation and innovative cryptocurrency investment opportunities.",
    link: '/projects/real-estate'
  },
  {
    icon: pro8,
    productName: 'Education',
    description: "An educational platform that offers high quality interactive resources initially designed for the medical community, and later extended to every educational discipline.",
    link: '/projects/educations'
  },
  {
    icon: pro9,
    productName: 'Financial Services',
    description: "Introducing an innovative app, revolutionizing traditional tandas: Seamlessly organize and participate in rotating savings and credit associations online, empowering communities to efficiently pool resources and achieve financial goals together.",
    link: '/projects/financial-services'
  },
  {
    icon: pro10,
    productName: 'Education Platform',
    description: "PP Platform is an app that offers a range of features for easy scheduling, communication, and management of childcare services. With its intuitive interface, PP Platform makes it simple for parents to find and book trusted childcare providers, while also providing providers with tools to streamline their operations.",
    link: '/projects/education-platform'
  },
];


const Projects = () => {
  ScrollToTopOnMount();
  return (
    <div className="page-main-wrapper f-wrp">
      <div className="project-main-wrapper f-wrp">
        <div className="container">
          <div className="banner-header">
            <ChevDown style={{ width: "56px", margin: "32px 16px", marginLeft: "0" }} /> Our projects
          </div>
          <div className="row">
            {prodcutDetails.map((element, key) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={key}>
                <Link to={element.link}>
                  <div className="each-project-block f-wrp">
                    <span>
                      <img src={element.icon} alt="product" />
                     {element.description ? <p>
                        <b>{element.description}</b>
                      </p> : null}  
                    </span>
                    <div className="proj-title-blk f-wrp">
                      <label>{element.productName}</label>
                      <span className="see-button">See more</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-content-wrp f-wrp p-5">
        <div className="container">
          <div className="foot-con-sec f-wrp blk-txt-wrp">
            <h1 className="main-title">Let’s do this!</h1>
            <Link to={"/start-my-project"} className="sqr-btn">
              Start my project
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
