import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mobileBG from "../../../../assets/images/projects/mobileBG.png"
import responseBG from "../../../../assets/images/projects/responsive-mob.png"
import PPBG from "../../../../assets/images/projects/PPBG.png";
import pro1 from "../../../../assets/images/carousel/PPPlatform/prop1.png";
import pro2 from "../../../../assets/images/carousel/PPPlatform/prop2.png";
import pro3 from "../../../../assets/images/carousel/PPPlatform/prop3.png";
import pro4 from "../../../../assets/images/carousel/PPPlatform/prop4.png";
import pro5 from "../../../../assets/images/carousel/PPPlatform/prop5.png";
import pro6 from "../../../../assets/images/carousel/PPPlatform/prop6.png";
import pro7 from "../../../../assets/images/carousel/PPPlatform/prop7.png";
import { Link } from "react-router-dom";
import mapPin from "../../../../assets/images/icons/MapPin.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/images/icons/ArrowRight.svg";
// import { ReactComponent as ChevDown } from "../../../../assets/images/icons/ChevDown.svg";
import "../projects.css";
import "./PPPlatform.css";
import { ScrollToTopOnMount } from "../../../../components/scrollToTop/scrollToTop";
import laptop from "../../../../assets/images/ppLaptop.png";

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  zoom: true,
  pauseOnHover: false
};



const PPPlatform = () => {
  ScrollToTopOnMount();
  return (
    <div className="page-main-wrapper PPplatform-project-wrapper f-wrp">

      <div className="project-wrapper f-wrp">
        <div className="f-wrp paddingTop75" />
        <div className="container-fluid">
          <div className="container">
            <div className="banner-header">
              {/* <ChevDown style={{ width: "56px", margin: "32px 16px", marginLeft: "0" }} /> Our projects */}
            </div>
            <div className="project-banner-wrapper f-wrp">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <div className="project-sub-banner f-wrp">
                    <span className="project-bg">
                      <img src={PPBG} alt="project1" />
                    </span>
                    <div className="banner__title">
                      <div className="chips-container">
                        <div className="chips">Web app</div>
                        <div className="chips">Mobile App</div>
                      </div>
                      <p className="banner-text">PP Platform</p>
                      <p>
                        <img className="icon" src={mapPin} alt="Map pin" /> California, US
                      </p>
                      <div >
                        <Link to={"https://www.evolvechildcare.com/"} target='_blank' className="see-button">See website <ArrowRight style={{ width: "24px" }} /></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12" style={{ position: 'relative' }}>
                  <div className="phone-wrp f-wrp" style={{ position: "relative", height: "100%" }}>
                    <div className="phone-sec-wrp mob-slider-bg f-wrp" style={{ position: 'absolute', right: '0', bottom: '0', width: '555px', height: '100%', }}>
                      <span className="mob-bg"><img src={mobileBG} alt="project1" /></span>
                      <span className="response-bg"><img src={responseBG} alt="project1" /></span>
                      <div className="soc-slider-wrp f-wrp">
                        <div className="soc-mob-bgimg"></div>
                        <Slider {...settings}>
                          <div>
                            <img src={pro1} alt="project1" />
                          </div>
                          <div>
                            <img src={pro2} alt="project1" />
                          </div>
                          <div>
                            <img src={pro3} alt="project1" />
                          </div>
                          <div>
                            <img src={pro4} alt="project1" />
                          </div>
                          <div>
                            <img src={pro5} alt="project1" />
                          </div>
                          <div>
                            <img src={pro6} alt="project1" />
                          </div>
                          <div>
                            <img src={pro7} alt="project1" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div className="container">
            <div className="project-discription-wrp f-wrp" style={{ paddingBottom: '0' }}>
              <div className="col-md-8">
                <p className="description">
                  PP Platform is an app that offers a range of features for easy scheduling, communication, and management of childcare services. With its intuitive interface, PP Platform makes it simple for parents to find and book trusted childcare providers, while also providing providers with tools to streamline their operations.
                </p>
              </div>
            </div>
            <div className="laptop-box">
              <img className="laptop" src={laptop} alt="pp-platform on laptop" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid challenge-box f-wrp">
        <div className="row">
          <div className="col-md-6 bg-white">
            <div className="flex-column h-100 col-align ">
              <h1 style={{ color: "#542D76" }} className="col-title">
                THE CHALLENGE
              </h1>
              <p className="text-dark left-col-text">
              Managing a childcare center is complex and time-consuming. Providers often struggle with tedious paperwork, inefficient processes, and difficulty staying organized.
              </p>
              <p className="text-dark left-col-text">
              Communication with parents can be challenging, and tracking child development may feel overwhelming.
              </p>
              <p className="text-dark left-col-text">
              Without the right tools, providers may find it difficult to deliver the level of care they aspire to, leading to frustration and stress.
              </p>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{ backgroundColor: "#542D76" }}
          >
            <div className="flex-column h-100 col-align-r">
              <div>
                <h1 className="col-title">THE SOLUTION</h1>
              </div>
              <div>
                <p className="right-col-text" style={{ fontSize: '32px' }}>
                The PP Platform - the ultimate solution for childcare center management.
                </p>
                <br></br>
                <p className="right-col-text--s">
                This comprehensive platform simplifies every aspect of running a childcare center, from enrollment and attendance tracking to scheduling and billing. With PP Platform, providers can say goodbye to paperwork and hello to efficiency, freeing up valuable time to focus on what truly matters, the children. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="project-switch-wrp f-wrp" style={{ paddingTop: '35px' }}>
            <ul>
              <li>
                <Link to={"/projects/financial-services"}>
                  <label>Previous</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/projects/teslabots"}>
                  <label>Next project</label>
                  <span className="longArrow">
                    <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42 11L2 11" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.5 1L1 10.5L10.5 20" stroke="#AEACAC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-content-wrp f-wrp p-5">
          <div className="foot-con-sec f-wrp blk-txt-wrp">
            <h1 className="main-title">Let’s do this!</h1>
            <Link to={"/start-my-project"} className="sqr-btn">
              Start my project
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PPPlatform;
