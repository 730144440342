import art1 from "../assets/images/Art/art1.png";
import art2 from "../assets/images/Art/art2.png";
import art3 from "../assets/images/Art/art3.png";
import art4 from "../assets/images/Art/art4.png";
import art5 from "../assets/images/Art/art5.png";
import art6 from "../assets/images/Art/art6.png";
import art7 from "../assets/images/Art/art7.png";
import art8 from "../assets/images/Art/art8.png";
export const artGalleryImages = [
    {
        id: 1,
        imageSRC: art1,
    },
    {
        id: 2,
        imageSRC: art2,
    },
    {
        id: 3,
        imageSRC: art3,
    },
    {
        id: 4,
        imageSRC: art4,
    },
    {
        id: 5,
        imageSRC: art5,
    },
    {
        id: 6,
        imageSRC: art6,
    },
    {
        id: 7,
        imageSRC: art7,
    },
    {
        id: 8,
        imageSRC: art8,
    },
    
]