import React, { lazy, Suspense } from "react";
import Loader from "./components/Loader";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import "./assets/css/custom.css";
import PublicLayout from "./layouts/public";
import { ToastContainer } from "react-toastify";
import { AnimatePresence } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import ThankScreen from "./pages/public/Contact/Contact10";
import Projects from "./pages/public/Project";
import PieceDetails from "./pages/public/pieceDetails";
import ArtistDetails from "./pages/public/ArtistDetails";
import Xmed from "./pages/public/Project/Xmed/xmed";
import Tandas from "./pages/public/Project/Tandas/tandas";
import PPPlatform from "./pages/public/Project/PPPlatform/PPPlatform";
import BlogPage from "./pages/public/Blog";

const HomeScreen = lazy(() => import("./pages/public/Home"));
const Teslabots = lazy(() =>
  import("./pages/public/Project/Blockchain/Teslabots")
);
const Shades = lazy(() => import("./pages/public/Project/Beauty/Shades"));
const Grace = lazy(() => import("./pages/public/Project/Cars/Grace"));
const Anyhnb = lazy(() => import("./pages/public/Project/Remodeling/Anyhnb"));
const Childcare = lazy(() =>
  import("./pages/public/Project/Education/SchoolsOfChildcare")
);
const Propshop = lazy(() => import("./pages/public/Project/Propshop/Propshop"));
const Solar = lazy(() => import("./pages/public/Project/Renewables/Solar"));
const Chatbot = lazy(() => import("./pages/public/Project/Health/Chatbot"));
const AboutUs = lazy(() => import("./pages/public/About/index"));
const ArtGallery = lazy(() => import("./pages/public/ArtGallery/ArtGallery"));

const Contact9 = lazy(() => import("./pages/public/Contact/Contact9"));
const Contact8 = lazy(() => import("./pages/public/Contact/Contact8"));
const Contact7 = lazy(() => import("./pages/public/Contact/Contact7"));
const Contact6 = lazy(() => import("./pages/public/Contact/Contact6"));
const Contact5 = lazy(() => import("./pages/public/Contact/Contact5"));
const Contact4 = lazy(() => import("./pages/public/Contact/Contact4"));
const Contact3 = lazy(() => import("./pages/public/Contact/Contact3"));
const Contact2 = lazy(() => import("./pages/public/Contact/Contact2"));
const Contact1 = lazy(() => import("./pages/public/Contact/Contact1"));
const NoPage = lazy(() => import("./pages/Error/NoPage"));
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <PublicLayout />
      </Suspense>
    ),
    children: [
      {
        path: "",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <HomeScreen />
          </Suspense>
        ),
      },
      {
        path: "home",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <HomeScreen />
          </Suspense>
        ),
      },
      {
        path: "start-my-project",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact1 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/email",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact2 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/phone",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact3 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/need-of-technology",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact4 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/features",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact5 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/secondary-features",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact6 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/expertise-for-develop",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact7 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/business-model",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact8 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/first-geography",
        element: (
          <Suspense fallback={<Loader />}>
            <Contact9 />
          </Suspense>
        ),
      },
      {
        path: "start-my-project/completed",
        element: (
          <Suspense fallback={<Loader />}>
            <ThankScreen />
          </Suspense>
        ),
      },
      {
        path: "projects",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Projects />
          </Suspense>
        ),
      },
      {
        path: "projects/teslabots",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Teslabots />
          </Suspense>
        ),
      },
      {
        path: "projects/shades",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Shades />
          </Suspense>
        ),
      },
      {
        path: "projects/grace",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Grace />
          </Suspense>
        ),
      },
      {
        path: "projects/anyhnb",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Anyhnb />
          </Suspense>
        ),
      },
      {
        path: "projects/education",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Childcare />
          </Suspense>
        ),
      },
      {
        path: "projects/real-estate",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Propshop />
          </Suspense>
        ),
      },
      {
        path: "projects/renewables",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Solar />
          </Suspense>
        ),
      },
      {
        path: "projects/health",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Chatbot />
          </Suspense>
        ),
      },
      {
        path: "projects/educations",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Xmed />
          </Suspense>
        ),
      },
      {
        path: "projects/financial-services",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <Tandas />
          </Suspense>
        ),
      },
      {
        path: "projects/education-platform",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <PPPlatform />
          </Suspense>
        ),
      },

      
      
      {
        path: "about-us",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <AboutUs />
          </Suspense>
        ),
      },
      {
        path: "blog",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <BlogPage />
          </Suspense>
        ),
      },
      {
        path: "art-gallery",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <ArtGallery />
          </Suspense>
        ),
      },
      {
        path: "art-gallery/:id",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <PieceDetails />
          </Suspense>
        ),
      },
      {
        path: "art-gallery",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <ArtGallery />
          </Suspense>
        ),
      },
      {
        path: "art",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <PieceDetails />
          </Suspense>
        ),
      },
      {
        path: "view-artist",
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <ArtistDetails />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={Loader}>
        <NoPage />
      </Suspense>
    ),
  },
]);

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <AnimatePresence exitBeforeEnter>
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  );
}

export default App;
