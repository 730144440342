import React, { useEffect, useState } from 'react';
// import axios from "axios";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import artBg from "../../../assets/images/art-bg.jpeg";
import bannerbg from "../../../assets/images/bannerbg.png";
import Monaco from "../../../assets/images/Monaco.png";
import { ScrollToTopOnMount } from "../../../components/scrollToTop/scrollToTop";

import "./blog.css";


const blogContent = [
  {
    className: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
    title: 'Very interesting blog title that can extend up to three  lines but not more',
    subtitle: 'This is a new blog 2',
    shortDescription: 'Short description of the blog 2',
    image: artBg,
    authName: 'Author Name',
    createdDate: '16/10/2024',
    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",

    chips: [
      { label: 'artificial intelligence' },
      { label: 'blockchain' },
    ],
  },
  {
    className: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
    title: 'Very interesting blog title ',
    subtitle: 'This is a new blog 2',
    shortDescription: 'Short description of the blog 2',
    image: bannerbg,
    authName: 'Author Name',
    createdDate: '16/10/2024',
    description: <><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                  <p>Our methodology removes effort from low value - time intensive tasks and focus on high value added ones. This allows you to access world class development, while cutting costs up to 40% versus traditional approaches. First we understand business, then we focus on tech. </p>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></>,
    chips: [
      { label: 'emerging technologies' },
    ],
  },
  {
    className: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
    title: 'Very interesting blog title that can extend up to three  lines but not more',
    subtitle: 'This is a new blog 2',
    shortDescription: 'Short description of the blog 2',
    image: Monaco,
    authName: 'Author Name',
    createdDate: '16/10/2024',
    description: 'Our methodology removes effort from low value - time intensive tasks and focus on high value added ones. This allows you to access world class development, while cutting costs up to 40% versus traditional approaches. First we understand business, then we focus on tech. ',
    chips: [
      { label: 'usability' },
      { label: 'accessibility' },
      { label: 'blockchain' },
    ],
  },

];


const chipItems = [
  {
    label: 'Beauty',
  },
  {
    label: 'Animals',
  },
  {
    label: 'artificial intelligence',
  },
  {
    label: 'emerging technologies',
  },
  {
    label: 'usability',
  },
  {
    label: 'Photography',    

  }
]

const BlogPage = () => {
  // const [dataArr, setDataArr] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [smShow, setSmShow] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleSelectBlog = (item) => {
    setSelectedBlog(item);
    setSmShow(true);
  };

  const handlePopupClose = (item) => {
    setSelectedBlog(null);
    setSmShow(false);
  };


//   useEffect(() => {
//     const fetchData = async () => {
//         try {
//             const response = await axios.get('https://dummyapi.online/api/movies');
//             setDataArr(response.data);
//         } catch (err) {
//           setError(err.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     fetchData();
// }, []);



  ScrollToTopOnMount();
  return (
    <div className="page-main-wrapper blog-main-wrapper f-wrp">
      <div className="blog-banner-wrapper f-wrp">
        <div className="container">
          <div className="blog-banner-content f-wrp">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="banner-txt f-wrp">
                  <h1 className="main-title">Popular entries</h1>
                  <ul className='art-tag-btn blog-tag-btn'>
                    {chipItems.map((element, key) => (
                      <li key={key}>
                        <span className="chip-btn">{element.label}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-sec-wrp f-wrp">
        <div className="container">
          <div className="blog-grid-wrp f-wrp">
            <div className="row">
              {blogContent.map((element, key) => (
                <div className={`col-lg-4 col-md-6 col-sm-12 col-xs-12 each-blk-sec`} key={key} onClick={() => handleSelectBlog(element)}>
                  <div className="each-blog-blk f-wrp" >
                    <span className="blog-img"><img src={element.image} alt={element.title} /></span>
                    <div className='flex-justify-wrp'>
                      <div className='box-1'>
                        {element.chips && <ul className='art-tag-btn blog-tag-btn'>
                          {element.chips.map((chip, index) => (
                            <li key={index}>
                              <span className="chip-btn">{chip.label}</span>
                            </li>
                          ))}
                        </ul>}
                        <h5>{element.title}</h5>
                      </div>
                      <div className='box-2'>
                        <div className="basic-details">
                          <p>{element.authName}</p>
                          <p><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none">
                            <path d="M12 1.875C9.99747 1.875 8.0399 2.46882 6.37486 3.58137C4.70981 4.69392 3.41206 6.27523 2.64572 8.12533C1.87939 9.97543 1.67888 12.0112 2.06955 13.9753C2.46023 15.9393 3.42454 17.7435 4.84055 19.1595C6.25656 20.5755 8.06066 21.5398 10.0247 21.9305C11.9888 22.3211 14.0246 22.1206 15.8747 21.3543C17.7248 20.5879 19.3061 19.2902 20.4186 17.6251C21.5312 15.9601 22.125 14.0025 22.125 12C22.122 9.3156 21.0543 6.74199 19.1562 4.84383C17.258 2.94567 14.6844 1.87798 12 1.875ZM12 19.875C10.4425 19.875 8.91993 19.4131 7.62489 18.5478C6.32985 17.6825 5.32049 16.4526 4.72445 15.0136C4.12841 13.5747 3.97246 11.9913 4.27632 10.4637C4.58018 8.93606 5.3302 7.53287 6.43154 6.43153C7.53288 5.3302 8.93607 4.58017 10.4637 4.27632C11.9913 3.97246 13.5747 4.12841 15.0136 4.72445C16.4526 5.32049 17.6825 6.32985 18.5478 7.62488C19.4131 8.91992 19.875 10.4425 19.875 12C19.8728 14.0879 19.0424 16.0896 17.566 17.566C16.0896 19.0424 14.0879 19.8728 12 19.875ZM18.375 12C18.375 12.2984 18.2565 12.5845 18.0455 12.7955C17.8345 13.0065 17.5484 13.125 17.25 13.125H12C11.7016 13.125 11.4155 13.0065 11.2045 12.7955C10.9935 12.5845 10.875 12.2984 10.875 12V6.75C10.875 6.45163 10.9935 6.16548 11.2045 5.9545C11.4155 5.74353 11.7016 5.625 12 5.625C12.2984 5.625 12.5845 5.74353 12.7955 5.9545C13.0065 6.16548 13.125 6.45163 13.125 6.75V10.875H17.25C17.5484 10.875 17.8345 10.9935 18.0455 11.2045C18.2565 11.4155 18.375 11.7016 18.375 12Z" fill="#AEACAC" />
                          </svg>&nbsp;{element.createdDate}</p>
                        </div>
                        <Link className="getintouch" ><span>Read more</span> <span className="longArrow">
                          <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M42 11L2 11" stroke="#8C36FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.5 1L1 10.5L10.5 20" stroke="#8C36FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </span></Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="footer-sec-wrp blk-txt-wrp f-wrp">
          <div className="container">
            <div className="footer-content-wrp f-wrp">
              <div className="foot-con-sec f-wrp">
                <h1 className="main-title">Let’s do this!</h1>
                <Link to={"/start-my-project"} className="sqr-btn">
                  Start my project
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={smShow}
        onHide={() => handlePopupClose()}
        aria-labelledby="blog-detail-popup"
        className='blog-detail-popup'
      >
        <Modal.Header className='close-btn-head' closeButton>
        </Modal.Header>
        <Modal.Body>
          {selectedBlog &&
            <div className='blog-detail-wrp each-blog-blk f-wrp'>
              <h5 style={{ fontWeight: '700' }}>{selectedBlog.title}</h5>
              {selectedBlog.chips && <ul className='art-tag-btn blog-tag-btn'>
                {selectedBlog.chips.map((chip, index) => (
                  <li key={index}>
                    <span className="chip-btn">{chip.label}</span>
                  </li>
                ))}
              </ul>}
              <div className="basic-details">
                <p>{selectedBlog.authName}</p>
                <p><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none">
                  <path d="M12 1.875C9.99747 1.875 8.0399 2.46882 6.37486 3.58137C4.70981 4.69392 3.41206 6.27523 2.64572 8.12533C1.87939 9.97543 1.67888 12.0112 2.06955 13.9753C2.46023 15.9393 3.42454 17.7435 4.84055 19.1595C6.25656 20.5755 8.06066 21.5398 10.0247 21.9305C11.9888 22.3211 14.0246 22.1206 15.8747 21.3543C17.7248 20.5879 19.3061 19.2902 20.4186 17.6251C21.5312 15.9601 22.125 14.0025 22.125 12C22.122 9.3156 21.0543 6.74199 19.1562 4.84383C17.258 2.94567 14.6844 1.87798 12 1.875ZM12 19.875C10.4425 19.875 8.91993 19.4131 7.62489 18.5478C6.32985 17.6825 5.32049 16.4526 4.72445 15.0136C4.12841 13.5747 3.97246 11.9913 4.27632 10.4637C4.58018 8.93606 5.3302 7.53287 6.43154 6.43153C7.53288 5.3302 8.93607 4.58017 10.4637 4.27632C11.9913 3.97246 13.5747 4.12841 15.0136 4.72445C16.4526 5.32049 17.6825 6.32985 18.5478 7.62488C19.4131 8.91992 19.875 10.4425 19.875 12C19.8728 14.0879 19.0424 16.0896 17.566 17.566C16.0896 19.0424 14.0879 19.8728 12 19.875ZM18.375 12C18.375 12.2984 18.2565 12.5845 18.0455 12.7955C17.8345 13.0065 17.5484 13.125 17.25 13.125H12C11.7016 13.125 11.4155 13.0065 11.2045 12.7955C10.9935 12.5845 10.875 12.2984 10.875 12V6.75C10.875 6.45163 10.9935 6.16548 11.2045 5.9545C11.4155 5.74353 11.7016 5.625 12 5.625C12.2984 5.625 12.5845 5.74353 12.7955 5.9545C13.0065 6.16548 13.125 6.45163 13.125 6.75V10.875H17.25C17.5484 10.875 17.8345 10.9935 18.0455 11.2045C18.2565 11.4155 18.375 11.7016 18.375 12Z" fill="#AEACAC" />
                </svg>&nbsp;{selectedBlog.createdDate}</p>
              </div>
              <span className="blog-img"><img src={selectedBlog.image} alt={selectedBlog.title} /></span>
              <p>{selectedBlog.description}</p>
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BlogPage;
