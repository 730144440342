import React from "react";
import { Link } from "react-router-dom";

const ThankScreen = () => {
  return (
    <div className='h-100'>
      <div className="align-items-center justify-content-center d-flex w-100 h-100 blk-txt-wrp" style={{flexDirection: 'column', textAlign: 'center'}}>
        <h1 className='text-dark'>Thanks, we'll get back to you soon!</h1>
        <p className='text-dark' style={{fontSize: '16px', paddingBottom: '10px', paddingTop: '10px'}}>In the meantime you can check some of our projects.</p>
        <Link className='text-dark sqr-btn' style={{paddingTop: '8px', paddingBottom: '8px'}} to={"/projects"} >
          Projects
        </Link>
      </div>
    </div>
  );
};

export default ThankScreen;
